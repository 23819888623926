<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Job Titles</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/admin/job-titles/create">
          <button class="button expanded">
            Add Job Title
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Default Cost Domain</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(jobTitle, index) in jobTitles"
              :key="`jt_${index}`">
              <td>{{ jobTitle.title }}</td>
              <td>{{ jobTitle.cost_domain.domain }}</td>
              <td class="text-right">
                <span
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="jobTitleInEdit = index, editTitle = jobTitle.title"
                  >edit</span>
                &nbsp;
                <span
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteJobTitle(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="jobTitleInEdit > -1" class="dialogue">
      <label>Title</label>
      <input type="text" v-model="editTitle" />
      <button
        @click="saveUpdate"
        class="button small">Save</button>
      <button
        @click="jobTitleInEdit = -1, editTitle = ''"
        class="button small float-right">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'JobTitlesIndex',
  data() {
    return {
      jobTitles: [],
      jobTitleInEdit: -1,
      editTitle: '',
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    deleteJobTitle(index) {
      const postData = {};
      postData.jobTitle = this.jobTitles[index];
      axios.post(`/jobTitles/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.jobTitles.splice(index, 1);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveUpdate() {
      const postData = {};
      postData.jobTitle = this.jobTitles[this.jobTitleInEdit];
      postData.editTitle = this.editTitle;
      axios.post(`/jobTitles/update.json?token=${this.token}`, postData)
        .then(() => {
          this.jobTitles[this.jobTitleInEdit] = { id: this.jobTitleInEdit, title: this.editTitle };
          this.editTitle = '';
          this.jobTitleInEdit = -1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    fetchJobTitles() {
      axios.get(`/jobTitles/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.jobTitles = response.data.jobTitles;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchJobTitles();
    }, 100);
  },
};
</script>
